import React, { useEffect } from 'react'
import { useRouter } from 'next/router'

import { useApp } from '@/core/contexts/app'
import Anchor from '@/components/base/anchor'
import { Column, Row, Container } from '@/components/base/gridview'
import Icon from '@/components/base/icon'
import Image from '@/components/base/image'
import pageService from '@/services/http/wap/page'

import Button from '@/components/base/button'
import { path } from '@/core/utils'
import { PanelProps } from './type'
import { classnames } from 'begonya/functions'

const Panel: React.FunctionComponent<PanelProps> = ({
  children,
  isOpen,
  logo,
  onClose,
}) => {
  const app = useApp()
  const router = useRouter()

  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const renderLanguageSelection = () => {
    if (app.languages.length > 1) {
      return (
        <ul className="language-list">
          {app.languages.map((language) => (
            <li key={language.id} className="language-list-item">
              <Button
                onClick={() => handleLanguageSelection(language.id)}
                className={classnames(
                  app.language === language.name && 'current'
                )}
              >
                {language.name.substring(0, 2)}
              </Button>
            </li>
          ))}
        </ul>
      )
    }
  }

  return (
    <div className={`panel panel--${isOpen ? 'show' : 'hide'}`}>
      <Container>
        <Row xs={{ align: 'center' }}>
          <Column
            sm={{ size: 4 }}
            md={{ size: 4 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
          >
            <Anchor className="panel-logo" onClick={() => router.push('/')}>
              <Image
                {...(logo?.src ? logo : app.theme.logo)}
                alt={app.page.title}
                height={50}
              />
            </Anchor>
          </Column>
          <Column
            sm={{ size: 8 }}
            md={{ size: 8 }}
            lg={{ size: 2 }}
            xl={{ size: 2 }}
            className="header-action"
          >
            <button
              className="menu-button panel-close-button"
              onClick={onClose}
            >
              <Icon name="icon-clear" size="medium" />
            </button>
            {renderLanguageSelection()}
          </Column>
        </Row>
      </Container>
      {children}
    </div>
  )
}

export default Panel

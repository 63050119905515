import React from 'react'

import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'

import Anchor from '@/components/base/anchor'

import Panel from './Panel'

import { NavigationProps } from './type'

const Navigation: React.FunctionComponent<NavigationProps> = ({
  data,
  isOpen,
  logo,
  onClose,
  menuActive,
  currentSection,
  scrollToSection,
  handleClick,
  setPanel,
}) => {
  const app = useApp()

  return (
    <Panel onClose={onClose} isOpen={isOpen} logo={logo}>
      <div className="navigation">
        <nav className="navigation-menu">
          <ul>
            {data.map((menuItem, index) => (
              <li
                key={index}
                className={classnames(
                  menuItem.className,
                  menuItem.children.length && 'hasSubMenu',
                  menuActive === index && 'active',
                  currentSection === menuItem.href && 'active'
                )}
              >
                {menuItem.href === 'catalogs' ||
                menuItem.href === 'tr-tr/kataloglar' ? (
                  <Anchor
                    className="menu-item"
                    href={menuItem.href}
                    dangerouslySetInnerHTML={{ __html: menuItem.title }}
                  />
                ) : app.page.path === 'homepage' ||
                  app.page.path === 'tr-tr/anasafya' ? (
                  <div
                    onClick={() => {
                      scrollToSection(menuItem.href)
                      handleClick(index)
                      setPanel('')
                    }}
                    onKeyDown={() => handleClick(index)}
                    role="button"
                    tabIndex={0}
                    dangerouslySetInnerHTML={{
                      __html: menuItem.title,
                    }}
                    className={classnames(
                      'menu-item',
                      menuItem.href === currentSection && 'active'
                    )}
                  />
                ) : (
                  <Anchor
                    className="menu-item"
                    href={menuItem.href}
                    dangerouslySetInnerHTML={{ __html: menuItem.title }}
                  />
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </Panel>
  )
}

export default Navigation

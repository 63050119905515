import { getProperty } from 'dot-prop'

import { Reference, ReferenceMapping } from '../types'

const getReferenceValue = (
  { type, ref }: Reference,
  refs: Record<string, any>[]
) => {
  if (ref) {
    const reference = refs.find((item) => item.id === ref)

    if (reference) {
      const types = ['image', 'file', 'video', 'link']

      if (types.includes(type)) {
        return reference.path
      } else {
        return reference
      }
    }
  }

  return null
}

const getReference = (
  tree: Record<string, any>,
  refs: Record<string, any>[]
) => {
  if (tree) {
    if ('type' in tree && 'ref' in tree) {
      tree = getReferenceValue(tree as Reference, refs) as Record<string, any>
    } else {
      for (const key in tree) {
        const branch = tree[key]

        if (branch) {
          if (Array.isArray(branch)) {
            tree[key] = branch.map((leaf) => getReference(leaf, refs))
          } else if (typeof branch === 'object') {
            if ('type' in branch) {
              if (branch.type === 'static') {
                tree[key] = branch.label
              } else {
                if ('ref' in branch) {
                  tree[key] = getReferenceValue(branch, refs)
                }
              }
            } else {
              tree[key] = getReference(branch, refs)
            }
          } else {
            tree[key] = branch
          }
        }
      }
    }
  }

  return tree
}

const getQueryMapping = (item: any, mappings: ReferenceMapping[]) => {
  const value: Record<string, any> = {}

  for (const mapping of mappings) {
    const property = getProperty(item, mapping.path)

    if (property) {
      if (typeof property === 'object') {
        if (Array.isArray(property)) {
          const data = []
          const properties = property as Array<Record<string, any>>

          for (const prop of properties) {
            const mappings = (mapping.children || []).map(
              ({ path, ...rest }) => {
                const splitted = path.split('.')
                const result = splitted[splitted.length - 1]

                return {
                  ...rest,
                  path: result,
                }
              }
            )
            const result = getQueryMapping(prop, mappings)

            data.push(result)
          }

          value[mapping.name] = data
        } else {
          if (property['value'] && property['refs']) {
            const data = getReference(property['value'], property['refs'])

            value[mapping.name] = data
          } else {
            value[mapping.name] = property
          }
        }
      } else {
        if (mapping.type === 'link') {
          value[mapping.name] = {
            href: property,
          }
        } else {
          value[mapping.name] = property
        }
      }
    }
  }

  return value
}

export const mapQuery = (
  query: Record<string, any>,
  mappings: ReferenceMapping[]
) => {
  if (Array.isArray(query)) {
    const data = []

    for (const item of query) {
      data.push(getQueryMapping(item, mappings))
    }

    return data
  } else {
    return getQueryMapping(query, mappings)
  }
}

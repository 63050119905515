export enum EntityFieldType {
  Boolean = 0,
  Color = 1,
  Date = 2,
  Editor = 3,
  File = 4,
  Image = 5,
  Link = 6,
  LongText = 7,
  Number = 8,
  Picture = 9,
  Price = 10,
  Reference = 11,
  Text = 12,
  Video = 13,
}

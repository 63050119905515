import React, { useEffect, useState } from 'react'
import Router, { useRouter } from 'next/router'

import { classnames } from 'begonya/functions'

import { useApp } from '@/core/contexts/app'
import { path } from '@/core/utils'
import { useSection } from '@/core/contexts/section'

import pageService from '@/services/http/wap/page'

import { Column, Container, Row } from '@/components/base/gridview'
import Image from '@/components/base/image'
import Anchor from '@/components/base/anchor/Anchor'
import Icon from '@/components/base/icon'
import Button from '@/components/base/button'

import { HeaderProps } from './type'
import { NavigationReference } from '@/services/http/wap/types'
import { useWidth } from '@/core/hooks/useWidthResize'
import Navigation from './Navigation'

const Header: React.FunctionComponent<HeaderProps> = ({ logo, navigation }) => {
  const app = useApp()
  const router = useRouter()
  const width = useWidth()

  const [panel, setPanel] = useState('')
  const [menuActive, setMenuActive] = useState(null)
  const [menu, setMenu] = useState<NavigationReference[]>([])
  const { currentSection, setCurrentSection } = useSection()

  const handleClick = (index: any) => {
    setMenuActive((prevIndex) => (prevIndex === index ? null : index))
  }

  const replaceHref = () => {
    if (navigation) {
      const updatedMenuItems = navigation.map((item) => {
        const updatedHref = item.href.replace('#', '')
        return { ...item, href: updatedHref }
      })

      setMenu(updatedMenuItems)
    }
  }

  useEffect(() => {
    replaceHref()
  }, [navigation])

  /* Language (Selection & Render) */
  const handleLanguageSelection = (language: string) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  const renderLanguageSelection = () => {
    if (app.languages.length > 1) {
      return (
        <ul className="language-list">
          {app.languages.map((language) => (
            <li key={language.id} className="language-list-item">
              <Button
                onClick={() => handleLanguageSelection(language.id)}
                className={classnames(
                  app.language === language.name && 'current'
                )}
              >
                {language.name.substring(0, 2)}
              </Button>
            </li>
          ))}
        </ul>
      )
    }
  }

  const scrollToSection = (id: string) => {
    const section = document.getElementById(id)
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <React.Fragment>
      <header
        className={classnames('header', panel === 'menu' && 'header--disable')}
      >
        <Container size="extended">
          <Row xs={{ justify: 'between', align: 'center' }}>
            <Column>
              <div className="logo">
                <Anchor href={app.settings.homepage}>
                  <Image
                    {...(logo?.src ? logo : app.theme.logo)}
                    alt={app.page.title}
                  />
                </Anchor>
              </div>
            </Column>
            {width > 999 && (
              <Column xs={{ size: 8 }}>
                <nav className="menu">
                  <ul>
                    {menu.map((menuItem, index) => (
                      <li
                        key={index}
                        className={classnames(
                          menuItem.className,
                          menuItem.children.length && 'hasSubMenu',
                          menuActive === index && 'active',
                          currentSection === menuItem.href && 'active'
                        )}
                      >
                        {menuItem.href === 'catalogs' ||
                        menuItem.href === 'tr-tr/kataloglar' ? (
                          <Anchor
                            className="menu-item"
                            href={menuItem.href}
                            dangerouslySetInnerHTML={{ __html: menuItem.title }}
                          />
                        ) : app.page.path === 'homepage' ||
                          app.page.path === 'tr-tr/anasafya' ? (
                          <div
                            onClick={() => {
                              scrollToSection(menuItem.href)
                              handleClick(index)
                            }}
                            onKeyDown={() => handleClick(index)}
                            role="button"
                            tabIndex={0}
                            dangerouslySetInnerHTML={{
                              __html: menuItem.title,
                            }}
                            className={classnames(
                              'menu-item',
                              menuItem.href === currentSection && 'active'
                            )}
                          />
                        ) : (
                          <Anchor
                            className="menu-item"
                            href={menuItem.href}
                            dangerouslySetInnerHTML={{ __html: menuItem.title }}
                          />
                        )}
                      </li>
                    ))}
                  </ul>
                </nav>
              </Column>
            )}
            <Column className="header-action">
              {width <= 999 && (
                <button
                  className="menu-button"
                  onClick={() => setPanel('menu')}
                >
                  <Icon name="icon-menu" size="medium" />
                </button>
              )}
              <div className="languages">{renderLanguageSelection()}</div>
            </Column>
          </Row>
        </Container>
      </header>
      {navigation ? (
        <Navigation
          isOpen={panel === 'menu'}
          data={menu}
          onClose={() => setPanel('')}
          logo={logo}
          menuActive={menuActive}
          currentSection={currentSection}
          scrollToSection={scrollToSection}
          handleClick={handleClick}
          setPanel={setPanel}
        />
      ) : null}
    </React.Fragment>
  )
}

export default Header
